import { Col, Container, Row } from "react-bootstrap"
import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTooth } from "@fortawesome/free-solid-svg-icons"
import Ruth from "../assets/svgs/Ruth.svg"
function getToothPrefixedLink(ref: string, nameOfLink: string) {
  return (
    <p className="mx-auto mx-md-0" style={{ padding: "10px" }}>
      <Link to={ref}>
        <FontAwesomeIcon icon={faTooth} style={{ height: "1em" }} />
        {` ${nameOfLink}`}
      </Link>
    </p>
  )
}

export const SoortenBeugelsNavigation = () => (
  <Container style={{ padding: 0, fontSize: "25px" }}>
    <Row>
      <Col md={3} lg className="my-auto">
        <Row>
          {getToothPrefixedLink(
            "/soorten-beugels/#uitneembare-apparatuur",
            "Uitneembare apparatuur"
          )}
        </Row>
        <Row>
          {getToothPrefixedLink(
            "/soorten-beugels/#buitenbeugel",
            "Buitenbeugel"
          )}
        </Row>
      </Col>
      <Col md={6} lg>
        <Row className="mx-auto">
          <Ruth id="ruth-nav-svg" />
        </Row>
      </Col>
      <Col md={3} lg className="my-auto">
        <Row className="mx-sm-auto">
          {getToothPrefixedLink(
            "/soorten-beugels/#vaste-apparatuur",
            "Vaste apparatuur"
          )}
        </Row>
        <Row className="mx-sm-auto">
          {getToothPrefixedLink(
            "/soorten-beugels/#botankers",
            "Recente botankers"
          )}
        </Row>
      </Col>
    </Row>
  </Container>
)
